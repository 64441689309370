body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: rgb(248, 249, 250);
}

a {
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.css-alayy9-MuiInputBase-root-MuiOutlinedInput-root {
  justify-content: space-between;
}

#mui-component-select-contentType {
  width: 100% !important;
}

.css-1i0az6v-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  width: 100% !important;
}

svg {
  display: block !important;
}

.optionListContainer {
  box-shadow: 0rem 0.5rem 1.625rem -0.25rem rgba(20, 20, 20, 0.15),
    0rem 0.5rem 0.5625rem -0.3125rem rgba(20, 20, 20, 0.06) !important;
  border: none;
}
/* .multiSelectContainer ul {
  border-radius: 0.75rem;
  border: none;
}*/
.searchWrapper {
  padding: 0.5rem 1.75rem 0.5rem 0.75rem !important;
  border: 0.0625rem solid #d2d6da;
  border-radius: 0.5rem;
  color: #495057 !important;
}

.optionContainer li:hover,
.optionContainer .highlight {
  background: #e9ecef;
  color: #fff;
}
